html {
    box-sizing: border-box;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

body {
    font-size: 16px;
    line-height: 20px;
    font-family: $fontFamily;
    color: $fontColor;
    font-weight: 400;
    background: #ffffff;
    min-width: 320px;
    overflow-x: hidden;
}


ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

li {
    margin: 0;
    padding: 0;
}

h1, h2, h3, h4 {
    margin: 0;
    font-weight: 400;
}

p {
    margin: 0;
}

b {
    font-weight: initial;
    font-family: $fontFamilySemibold;
}

a {
    display: inline-block;
    text-decoration: none;
    color: $fontColor;
}

img {
    max-width: 100%;
    height: auto;
}
i.ruble-sign {
    display: inline-block;
    font-family: $fontFamilyBold;
    font-size: inherit;
    line-height: inherit;
    font-style: normal;

    &::before {
        display: block;
        content: 'R';
        color: transparent;
        background: url('#{$assetsPath}/img/ruble-sign.svg') no-repeat center / contain;
    }

    &--gray::before {
        background-color: #4f4f4f;
    }

    &--lightgray::before {
        background-color: #bdbdbd;
    }

    &--green::before {
        background-color: #35c768;
    }

    &--orange::before {
        background-color: #f2994a;
    }

    &--lightgray::before {
        background-color: #bdbdbd;
    }
}


//Main styles
.container {
    width: 100%;
    max-width: 1210px;
    margin: 0 auto;
    padding: 0 20px;
}

.header-new {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    max-width: 100%;
    background: #ffffff;
    padding: 15px 0;
    font-family: $fontFamily;
    z-index: 99991;

    &__top-content {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .phone {
            @media (max-width: 1023px) {
                display: none;
            }
        }
    }


    .hamburger {
        width: 25px;
        padding: 9px 0 0 0;
        margin-right: 40px;
        display: none;

        @media (max-width: 1123px) {
            display: block;
        }

        .hamburger-box {
            width: 25px;
            height: 20px;
        }

        .hamburger-inner,
        .hamburger-inner::after,
        .hamburger-inner::before {
            width: 25px;
            height: 2px;
            background-color: #2c3e50;
            border-radius: 2px;
        }

        .hamburger-inner::before {
            top: -8px;
        }

        .hamburger-inner::after {
            top: -16px;
        }

        &.is-active {
            position: static;

            .hamburger-inner::after,
            .hamburger-inner::before {
                top: 0;
            }
        }
    }

    &__logo-container {
        flex-grow: 1;
        display: flex;
        align-items: center;

        img {
            margin-right: 30px;
            width: 113px;
            height: auto;
        }
    }

    &__top-links {
        margin: auto;

        &.hide-elements {
            opacity: 0;
            pointer-events: none;
        }

        a {
            margin-right: 45px;
            font-size: 16px;
            line-height: 20px;
            font-family: $fontFamily;
            color: #34495E;
            transition: color 0.5s ease;

            &:last-child {
                margin-right: 0;
            }

            &.active {
                color: $green;
                pointer-events: none;
            }

            &:hover,
            &:focus,
            &:active {
                color: $green;
            }
            @media (max-width: 1200px) {
                font-size: 15px;
                line-height: 20px;
            }
        }
        @media (max-width: 1023px) {
            display: none;
        }
    }

    &__insurance-link,
    &__viza-link {
        &.header-new__viza-link--mobile,
        &.header-new__insurance-link--mobile {
            display: none;
        }
    }

    &__info-container {
        flex-shrink: 0;
        display: flex;
        align-items: center;
    }

    &__search-popular-link-blog {
        margin-left: 10px;
        color: #8f91a6;
        font-size: 12px;
        line-height: 15px;
        font-family: $fontFamily;
    }

    &__collapse {
        background: #ffffff;
        color: #303030;

        &::before {
            display: none;
        }

        .block-wrap {
            width: 100%;
            height: 100%;
            max-height: 100%;
            padding-top: 126px;

            .container-new {
                padding-top: 50px;
                padding-bottom: 50px;
                height: 100%;
            }
        }
    }

    &__collapse-content {
        display: flex;
        align-items: flex-start;
        text-align: left;
        font-family: $fontFamily;
        height: 100%;
    }

    &__menu {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 92%;
        margin-right: 8%;
        height: 100%;

        .header-new__menu-item {
            &:not(:first-of-type) {
                margin-top: 30px;
            }

            &--with-arrow {
                a {
                    position: relative;
                    padding-right: 30px;

                    &::after {
                        content: "";
                        position: absolute;
                        width: 12px;
                        height: 12px;
                        top: 5px;
                        right: 0;
                        transform: rotate(45deg);
                        border-top: 2px solid #828282;
                        border-right: 2px solid #828282;
                        transition: all 0.5s ease;
                    }

                    &.active::after {
                        top: 2px;
                        transform: rotate(135deg);
                        border-color: $green;
                    }
                }
            }

            a {
                display: inline-block;
                font-size: 15px;
                line-height: 20px;
                transition: color 0.5s ease;
                text-decoration: none;

                &:not(.active) {
                    cursor: pointer;

                    &:hover,
                    &:active {
                        color: $green;
                        text-decoration: none;
                    }
                }

                &.active {
                    text-decoration: none;
                    color: $green;
                }
            }

            p {
                display: none;
                margin-top: 20px;
                margin-bottom: 0;
                font-size: 16px;
                line-height: 20px;
            }
        }
    }

    &__contacts {
        display: block;
        margin-top: auto;
    }

    &__content {
        margin-top: 25px;
        width: 60%;
        z-index: 1;
    }

    &__content-item-wrapper {
        display: none;
    }

    &__content-back-btn {
        display: none;
    }

    &__insurance-additional-container {
        height: 100%;
    }

    &__insurance-additional {
        display: none;
    }

    &__content-item {
        min-height: calc(100vh - 220px);
        height: calc(100vh - 220px);
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        overflow-y: auto;

        a {
            max-height: 16px;
            margin-bottom: 30px;
            flex: 1 1 16px;
            display: block;
            color: #303030;
            font-size: 16px;
            line-height: 16px;
            transition: color 0.5s ease;
            text-decoration: none;

            &:last-child {
                margin-bottom: 0;
            }

            &:hover,
            &:focus,
            &:active {
                color: $green;
            }

            &.active {
                color: $green;
                pointer-events: none;
            }

            &.active-with-event {
                color: $green;
            }
        }
    }

    &__show-insurance-btn {
        display: block;
        background: transparent;
        border: none;
        outline: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        font-size: 16px;
        line-height: 20px;
        font-family: $fontFamilyBold;
        color: #5199FF;
    }
}

.phone {
    font-size: 16px;
    line-height: 20px;
    color: #35C768;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    &-icon {
        width: 15px;
        height: 15px;
        display: block;
        background: url('#{$assetsPath}/img/phone.svg') no-repeat center center;
        margin-right: 10px;
        flex-shrink: 0;
        background-size: 100%;
    }
    @media (max-width: 550px) {
        order: -1;
        margin-bottom: 20px;
    }
}

.content-block {
    padding: 80px 0;
    background: #F2F8FD;

    &--white {
        background: #ffffff;
    }

    &__title {
        margin-bottom: 30px;
        text-align: center;
        font-size: 27px;
        line-height: 30px;
        font-family: $fontFamilyBold;
        color: $fontColor;

        a {
            color: $green;
            cursor: pointer;
        }
    }

    &__subtitle {
        margin-bottom: 50px;
        text-align: center;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        color: #676767;
    }

    &__no-subtitle {
        margin-bottom: 50px;
    }
}

.page-footer {
    padding: 25px;

    &__wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media (max-width: 550px) {
            flex-direction: column;
        }
    }

    &__logo {
        color: #676767;
        img {
            display: block;
        }
    }

    .phone {
        @media (max-width: 550px) {
            order: -1;
            margin-bottom: 20px;
        }
    }

    &__polis-link {
        margin-left: 30px;
        font-size: 20px;
        line-height: 20px;
        font-family: $fontFamilyItalic;
        color: #ffffff;

        a {
            color: #ffffff;
            font-family: $fontFamilyBold;
            transition: color 0.5s ease;

            &:hover,
            &:active {
                color: $green;
            }
        }
    }
}

@media (max-width: 1209px) {
    .content-block {
        padding: 60px 0;

        &__title {
            margin-bottom: 40px;
        }
    }
}

@media (max-width: 1023px) {
    .content-block {
        &__title {
            font-size: 24px;
            line-height: 29px;
        }
    }
}

@media (max-width: 767px) {
    .container {
        padding: 0 16px;
    }

    .content-block {
        padding: 50px 0;

        &__title {
            margin-bottom: 20px;
                font-size: 30px;
                line-height: 36px;
        }
        &__subtitle {
            margin-bottom: 40px;
            font-size: 20px;
            line-height: 30px;
        }
        &__no-subtitle {
            margin-bottom: 40px;
        }
    }
}

@media (max-width: 550px) {
    .content-block {
        padding: 30px 0;

        &__title {
            font-size: 18px;
            line-height: 22px;
            margin-bottom: 15px;
        }

        &__subtitle {
            margin-bottom: 25px;
            font-size: 14px;
            line-height: 19px;
        }
        &__no-subtitle {
            margin-bottom: 25px;
        }
    }
}

.tippy-box[data-theme~='tippy-tooltip'] {
    font-size: 12px;
    line-height: 14px;
    text-align: left;
    font-family: $fontFamilySemibold;
    color: #303030;
    box-shadow: 4px 4px 14px 3px rgba(51, 51, 51, 0.2);
    border-radius: 8px;
    padding: 5px 7px;
    background: #ffffff;
}

.tippy-box[data-theme~='tippy-tooltip'][data-placement^='top'] > .tippy-arrow::before {
    border-top-color: #ffffff;
}
.tippy-box[data-theme~='tippy-tooltip'][data-placement^='bottom'] > .tippy-arrow::before {
    border-bottom-color: #ffffff;
}
.tippy-box[data-theme~='tippy-tooltip'][data-placement^='left'] > .tippy-arrow::before {
    border-left-color: #ffffff;
}
.tippy-box[data-theme~='tippy-tooltip'][data-placement^='right'] > .tippy-arrow::before {
    border-right-color: #ffffff;
}
