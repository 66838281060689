$fontColor: #303030;
$green: #79C930;
$gray: #F2F8FD;
$blue: #34495E;

$fontFamily: 'LatoWeb', sans-serif;
$fontFamilyMedium: 'LatoWebMedium', sans-serif;
$fontFamilyItalic: 'LatoWebMediumItalic', sans-serif;
$fontFamilySemibold: 'LatoWebSemibold', sans-serif;
$fontFamilyBold: 'LatoWebBold', sans-serif;

$assetsPath: '../assets';
$publicPath: 'https://polis812.ru';

@mixin transition($transition) {
    -webkit-transition: $transition;
    -moz-transition: $transition;
    -ms-transition: $transition;
    transition: $transition;
}
