.vzr-form {
    width: 600px;
    padding: 30px;
    background: #ffffff;
    border-radius: 15px;

    &__title {
        margin-bottom: 20px;
        text-align: center;
        font-size: 18px;
        font-family: $fontFamilySemibold;
    }

    &__country-select-wrapper {
        margin-bottom: 20px;
    }

    &__days-select,
    &__ages-select.multi,
    &__country-select.multi,
    {
        width: 100%;

        &.error {
            border: 1px solid red;
            border-radius: 5px;
        }

        .selectize-input {
            height: 55px;
            display: flex;
            padding: 15px 20px;
            justify-content: flex-end;
            border: 1px solid #F3F3F3;
            box-shadow: none;

            &::after {
                visibility: hidden;
            }

            &.has-items {
                padding: 15px 20px;
            }

            input {
                position: relative !important;
                margin-right: auto !important;
                border: none;
                color: transparent;
                text-shadow: 0 0 0 gray;
                text-align: center;
                font-size: 14px;
                line-height: 19px;
                font-family: $fontFamilySemibold;

                &::placeholder {
                    color: #979797;
                }

                &:focus {
                    outline: none;
                }
            }

            & > div {
                flex-shrink: 0;
                max-width: 200px;
                margin: 0 20px 0 0;
                padding: 0 34px 0 0 !important;
                white-space: nowrap;
                text-overflow: ellipsis;
                font-size: 14px;
                line-height: 27px;
                font-family: $fontFamilySemibold;
                overflow: hidden;
                background: transparent;

                &.active,
                &:hover,
                &:focus,
                &:active {
                    border: none;
                    background: transparent;
                }

                a.remove {
                    width: 24px;
                    height: 24px;
                    border: none;
                    font-size: 0;
                    background: transparent url('#{$assetsPath}/img/ico-cross.svg') no-repeat center;
                    background-size: cover;

                    &:hover,
                    &:focus,
                    &:active {
                        background: transparent url('#{$assetsPath}/img/ico-cross.svg') no-repeat center;
                        background-size: cover;
                    }
                }
            }
        }

        .selectize-dropdown {
            border: 1px solid #F3F3F3;
            box-shadow: none;

            div.option {
                padding: 5px 20px;
                font-size: 14px;
                line-height: 19px;
                color: $fontColor;
                font-family: $fontFamily;
                cursor: pointer;

                &.active,
                &:hover,
                &:focus,
                &:active {
                    background: transparent;
                    color: $green;
                }
            }
        }
    }

    &__days-select {
        display: none;
        width: 50%;
    }

    &__ages-select.multi {
        width: calc(100% - 70px);
    }

    &__content-wrapper {
        display: flex;
        align-items: flex-start;
        margin-bottom: 20px;
    }

    &__dates {
        position: relative;
        display: flex;
        width: 320px;
        height: 55px;
        margin-right: 20px;

        input {
            width: 50%;
            height: 100%;
            padding: 18px 20px;
            font-size: 14px;
            line-height: 19px;
            outline: none;
            border: 1px solid #F3F3F3;
        }

        &::before,
        .vzr-form__dates-clear-btn {
            content: '';
            position: absolute;
            top: 15px;
            width: 24px;
            height: 24px;
            background-size: cover;
            transform: translateX(-44px);
        }

        &::before {
            left: 50%;
            background: url('#{$assetsPath}/img/calendar.svg') no-repeat center;
        }

        .vzr-form__dates-clear-btn {
            display: none;
            left: 100%;
            padding: 0;
            background: url('#{$assetsPath}/img/ico-cross.svg') no-repeat center;
            border: none;
            outline: none;
            cursor: pointer;
            z-index: 1;
        }
    }

    &__date-from {
        border-radius: 5px 0 0 5px;

        &.error {
            border-color: red;
        }
    }

    &__date-to {
        border-radius: 0 5px 5px 0;

        &.error {
            border-color: red;
        }
    }

    &__date-from,
    &__date-to {
        font-size: 14px;
        line-height: 19px;
        font-family: $fontFamilySemibold;
    }

    &__days-select {
        .selectize-input {
            border-radius: 0 5px 5px 0;

            &.error {
                border-color: red;
            }
        }
    }

    &__checkboxes {
        label {
            position: relative;
            display: block;
            padding-left: 30px;
            font-size: 12px;
            line-height: 20px;
            font-family: $fontFamilySemibold;
            cursor: pointer;

            &:first-of-type {
                margin-bottom: 15px;
            }

            &::before {
                display: block;
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 20px;
                height: 20px;
                border: 1px solid #F3F3F3;
                border-radius: 5px;
            }

            &::after {
                display: none;
                content: '';
                position: absolute;
                top: 3px;
                left: 7px;
                width: 6px;
                height: 11px;
                border-right: 2px solid #ffffff;
                border-bottom: 2px solid #ffffff;
                border-radius: 2px;
                transform: rotate(45deg);
            }
        }

        input {
            display: none;

            &:checked + label {
                &::before {
                    background-color: $green;
                    border-color: $green;
                }

                &::after {
                    display: block;
                }
            }
        }
    }

    &__ages {
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
    }

    &__age-button {
        display: block;
        width: 55px;
        height: 55px;
        padding: 0;
        margin: 0;
        outline: none;
        background: transparent url('#{$assetsPath}/img/add-tourist.png') no-repeat center;
        background-size: 24px 20px;
        border: 1px dashed #54B536;
        border-radius: 5px;
        cursor: pointer;
    }

    &__btn {
        width: 100%;
        padding: 10px;
        background: $green;
        font-size: 20px;
        line-height: 30px;
        font-family: $fontFamilyBold;
        color: #ffffff;
        text-align: center;
        border-radius: 5px;
        outline: none;
        border: none;
        cursor: pointer;
    }
}

@media (max-width: 1209px) {
    .vzr-form {
        width: 580px;
        padding: 30px 20px;
    }
}

@media (max-width: 1023px) {
    .vzr-form {
        width: 600px;
        padding: 30px;
    }
}

@media (max-width: 767px) {
    .vzr-form {
        width: 100%;
        padding: 30px 16px;

        &__title {
            font-size: 14px;
        }

        &__country-select-wrapper {
            margin-bottom: 15px;
        }

        &__content-wrapper {
            flex-direction: column;
            justify-content: flex-start;
        }

        &__days-select,
        &__ages-select.multi,
        &__country-select.multi,
        {
            .selectize-input {
                padding: 15px 10px;

                &.has-items {
                    padding: 15px 10px;
                }

                input {
                    font-size: 12px;
                }

                & > div {
                    font-size: 12px;
                    margin: 0 10px 0 0;
                    padding: 0 24px 0 0 !important;

                    a.remove {
                        margin-top: 3px;
                        width: 20px;
                        height: 20px;
                    }
                }
            }
            .selectize-dropdown {
                div.option {
                    padding: 5px 10px;
                    font-size: 12px;
                }
            }
        }

        &__dates {
            width: 100%;
            margin-bottom: 10px;
            margin-right: 0;

            input {
                padding: 18px 10px;
                font-size: 12px;
            }

            &::before,
            .vzr-form__dates-clear-btn {
                top: 18px;
                width: 20px;
                height: 20px;
                transform: translateX(-30px);
            }
        }

        &__checkboxes {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            label:first-of-type {
                margin-bottom: 0;
            }
        }

        &__ages {
            flex-direction: column;
            justify-content: flex-start;
        }

        &__ages-select.multi {
            width: 100%;
        }

        &__age-button {
            width: 100%;
            height: 44px;
            margin-top: 15px;
        }

        &__btn {
            font-size: 18px;
        }
    }
}
