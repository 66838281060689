
.header-mobile {
    z-index: 101;
    border-bottom: 1px solid #f4f4f5;
    padding-top: 15px;
    padding-bottom: 10px;
    position: relative;
    min-height: 75px;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #fff;
    }
}

.header-mobile__logo {
    max-width: 160px;
    position: relative;
    z-index: 101;
}

.hamburger {
    margin-top: 14px;
    padding: 10px 0px;
    outline: none;
    border: none;
    box-shadow: none;
}

.burger-sm {
    margin-top: 0px;
    padding: 0;
    vertical-align: 4px;

    .hamburger-box {
    }
}

.hamburger.is-active {
    position: absolute;
    top: 0px;
    right: 15px;
    z-index: 100;
}

.hamburger-box {
    position: relative;
    display: inline-block;
    width: 30px;
    height: auto;
}

.hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
    background-color: black;
    border-radius: 3px;
    height: 3px;
    width: 30px;
}

.hamburger.is-active .hamburger-inner, .hamburger.is-active .hamburger-inner:after, .hamburger.is-active .hamburger-inner:before {
    @include transition(all 0.5s ease);
}

.burger-sm .hamburger-inner, .burger-sm .hamburger-inner:after, .burger-sm .hamburger-inner:before {
    background: #0ec3c6;
    border-radius: 3px;
    width: 20px
}

.burger-sm .hamburger-inner:after {
    top: 7px;
}

.burger-sm .hamburger-inner:before {
    top: -7px;
}

body {
    &.menuOpen {
        overflow-y: hidden;
        height: 100%;
        position: fixed;
        width: 100%;

        header {
            position: static;
        }
    }
}

.mobile-nav {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    padding: 0;
    text-align: center;
    color: white;
    font-size: 18px;
    max-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;

    .block-wrap {
        width: 100%;
        min-height: 100%;
        position: relative;
        padding: 74px 0 0 0;
        z-index: 85;

        .main-navi {
            background-color: #fff;
            margin: 0;

            li {
                display: block;
                font-size: 17px;
                font-family: $fontFamilyBold;
                text-align: left;
                color: rgb(16, 20, 28);
                letter-spacing: 0;
                line-height: 2;
                margin: 0;
                padding-left: 40px;

                a {
                    display: inline-block;
                }

                &:hover {
                    background: rgb(243, 250, 241);
                    background: -webkit-gradient(
                                    linear,
                                    left top,
                                    right top,
                                    color-stop(43%, rgba(243, 250, 241, 1)),
                                    color-stop(70%, rgba(251, 255, 250, 1))
                    );
                    background: -o-linear-gradient(
                                    left,
                                    rgba(243, 250, 241, 1) 43%,
                                    rgba(251, 255, 250, 1) 70%
                    );
                    background: linear-gradient(
                                    90deg,
                                    rgba(243, 250, 241, 1) 43%,
                                    rgba(251, 255, 250, 1) 70%
                    );

                    a {
                        color: #54b536;
                    }

                }
            }

            li.active {
                background: rgb(243, 250, 241);
                background: -webkit-gradient(
                                linear,
                                left top,
                                right top,
                                color-stop(43%, rgba(243, 250, 241, 1)),
                                color-stop(70%, rgba(251, 255, 250, 1))
                );
                background: -o-linear-gradient(
                                left,
                                rgba(243, 250, 241, 1) 43%,
                                rgba(251, 255, 250, 1) 70%
                );
                background: linear-gradient(
                                90deg,
                                rgba(243, 250, 241, 1) 43%,
                                rgba(251, 255, 250, 1) 70%
                );
                color: #54b536 !important;
            }

            li.first {
                padding-top: 10px;
            }

            li.last {
                padding-bottom: 10px;
            }
        }

        .mobile-nav-contact {
            color: white;
            bottom: 0px;
            left: 0px;
            width: 100%;
            font-size: 28px;
            line-height: 1em;
            padding: 15px 0;
            text-align: left;
            background-color: #f1f4fb;

            div {
                &:not(.header-phone) {
                    text-transform: initial;
                    letter-spacing: 0;
                    font-size: 14px;
                    color: rgb(108, 115, 131);
                }
            }

            .header-phone {
                color: #34495e;
                font-family: $fontFamilyBold;
                padding: 0 40px;
            }

            .header-adress {
                border-color: white;
                display: inline-block;
                font-size: 14px;
                font-family: "LatoWebBold", sans-serif;
                letter-spacing: 0;
                color: rgb(108, 115, 131);
                padding: 0 40px;
            }

            .header-region {
                color: white;
                border-color: white;
                display: inline-block;
                font-size: 13px;
                font-family: "LatoWeb", sans-serif;
                text-transform: uppercase;
            }

            .header-top__navi {
                text-align: left;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                border-bottom: 1px solid #d7dff0;
                margin-bottom: 15px;
                padding: 0 0 15px;

                a {
                    font-size: 14px;
                    color: rgb(108, 115, 131);
                    text-transform: initial;
                    padding: 0 40px;
                }
            }
        }
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 80;
        -webkit-backdrop-filter: blur(2px);
        backdrop-filter: blur(2px);
        background-color: rgba(121, 123, 128, 0.2);
    }
}

.hamburger {
    padding: 15px;
    display: inline-block;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: .15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible
}

.hamburger:hover {
    opacity: .7
}

.hamburger-box {
    width: 40px;
    height: 24px;
    display: inline-block;
    position: relative
}

.hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px
}

.hamburger-inner, .hamburger-inner::after, .hamburger-inner::before {
    width: 40px;
    height: 4px;
    background-color: #000;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: .15s;
    transition-timing-function: ease
}

.hamburger-inner::after, .hamburger-inner::before {
    content: "";
    display: block
}

.hamburger-inner::before {
    top: -10px
}

.hamburger-inner::after {
    bottom: -10px
}

.hamburger--collapse .hamburger-inner {
    top: auto;
    bottom: 0;
    transition-duration: .15s;
    transition-delay: .15s;
    transition-timing-function: cubic-bezier(.55, .055, .675, .19)
}

.hamburger--collapse .hamburger-inner::after {
    top: -20px;
    transition: top .3s .3s cubic-bezier(.33333, .66667, .66667, 1), opacity .1s linear
}

.hamburger--collapse .hamburger-inner::before {
    transition: top .12s .3s cubic-bezier(.33333, .66667, .66667, 1), transform .15s cubic-bezier(.55, .055, .675, .19)
}

.hamburger--collapse.is-active .hamburger-inner {
    transform: translate3d(0, -10px, 0) rotate(-45deg);
    transition-delay: .32s;
    transition-timing-function: cubic-bezier(.215, .61, .355, 1)
}

.hamburger--collapse.is-active .hamburger-inner::after {
    top: 0;
    opacity: 0;
    transition: top .3s cubic-bezier(.33333, 0, .66667, .33333), opacity .1s .27s linear
}

.hamburger--collapse.is-active .hamburger-inner::before {
    top: 0;
    transform: rotate(-90deg);
    transition: top .12s .18s cubic-bezier(.33333, 0, .66667, .33333), transform .15s .42s cubic-bezier(.215, .61, .355, 1)
}
