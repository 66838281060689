.main-content {
    padding-top: 50px;
    padding-bottom: 60px;

    &__logo {
        width: 180px;
        display: block;
        margin: 0 auto 20px;

        img {
            display: block;
        }
    }

    &__polis-link {
        margin-bottom: 68px;
        font-size: 18px;
        line-height: 22px;
        font-family: $fontFamilyItalic;
        text-align: center;
        color: $blue;

        a {
            color: $blue;
            font-family: $fontFamilyBold;
            transition: color 0.5s ease;

            &:hover,
            &:active {
                color: $green;
            }
        }
    }

    &__title {
        margin-bottom: 15px;
        font-size: 30px;
        line-height: 35px;
        color: $green;
        text-align: center;
        font-family: $fontFamilyBold;
    }

    &__text {
        margin-bottom: 40px;
        font-size: 20px;
        line-height: 22px;
        text-align: center;
        font-family: $fontFamilyMedium;
    }

    &__form {
        min-height: 385px;
        padding-left: calc(100% - 600px);
        background: url('#{$assetsPath}/img/promo.png') no-repeat left center;
        background-size: calc(100% - 670px) auto;
    }
}

.promo {
    margin-top: 50px;
    &__content {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__content-text {
        width: 48.7%;
    }

    &__content-img {
        width: 45.5%;
    }

    &__content-title {
        font-family: $fontFamilyBold;
        font-size: 50px;
        line-height: 60px;
        margin-bottom: 40px;
    }

    &__message {
        margin-bottom: 30px;
        font-size: 20px;
        line-height: 24px;
    }

    &__button {
        margin-top: 40px;
        button {
        background: #FFAD32;
        border-radius: 10px;
        width: 260px;
        height: 50px;
        font-family: $fontFamilyBold;
        font-size: 18px;
        line-height: 20px;
        color: #FFFFFF;
        border: none;
        cursor: pointer;
        &:active,
        &:hover {
            opacity: 0.7;
        }
        }
    }

    &__item {
        position: relative;
        padding-top: 8px;
        margin-bottom: 25px;

        &:last-child {
            margin-bottom: 0;
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 35px;
            height: 35px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
        }

        &--ill::before {
            background-image: url('#{$assetsPath}/img/bacteria.svg');
        }

        &--evacuation::before {
            background-image: url('#{$assetsPath}/img/truck.svg');
        }

        &--accident::before {
            background-image: url('#{$assetsPath}/img/footprint.svg');
        }
    }

    &__item-title {
        display: block;
        margin-bottom: 12px;
        font-size: 18px;
        font-family: $fontFamilySemibold;
    }

    &__item-text {
        line-height: 20px;
    }
}

.steps,
.advantages,
.reviews,
.about {
    &__list {
        display: flex;
        justify-content: space-between;
    }

    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: calc(33.333% - 20px);
        border-radius: 5px;
    }
}

.steps {
    &__item {
        min-height: 350px;
        padding: 270px 24px 40px;
        background: #FFFFFF no-repeat center 30px;
        background-size: 220px 220px;
        text-align: center;

        &--calculation {
            background-image: url('#{$assetsPath}/img/calculator.svg');
        }

        &--program {
            background-image: url('#{$assetsPath}/img/documents.svg');
        }

        &--policy {
            background-image: url('#{$assetsPath}/img/credit-card.svg');
        }
    }
}

.info {
    padding-top: 0 !important;
    background: url('#{$assetsPath}/img/plane_2.svg') no-repeat right bottom;
    h1, h2 {
        font-family: $fontFamilyBold;
        font-size: 20px;
        line-height: 24px;
        color: #303030;
    }
    &__block {
        background: #F7F8FA;
        border-radius: 13px;
        margin-top: 30px;
        padding: 40px;
        p {
            font-size: 16px;
            line-height: 21px;
            color: #4F4F4F;
            margin-top: 20px;
        }
    }
    @media (max-width: 767px) {
        h1, h2 {
            font-size: 20px;
            line-height: 24px;
        }
        &__block p {
                    font-size: 14px;
                    line-height: 21px;
        }
    }
}

.policy__info {
    display: flex;
    width: 100%;
    gap: 30px;
    a {
        color: #5199FF;
    }
    &__goals {
        background: #f7f8fa;
        border-radius: 13px;
        display: flex;
        flex-direction: column;
        padding: 40px;
        gap: 30px;
        width: calc(50% - 15px);
        ol {
            counter-reset: orderedlist;
            list-style: none;
            display: grid;
            padding: 0;
            grid-gap: 1rem;
            li {
                display: grid;
                grid-template-columns: 0 1fr;
                grid-gap: 45px;
                &::before {
                    background-color: rgba(159, 226, 172, 0.5);
                    border-radius: 50%;
                    width: 30px;
                    height: 30px;
                    color: #36BD7C;
                    counter-increment: orderedlist;
                    content: counter(orderedlist);
                    font-size: 1.25em;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .goal__title {
                    font-family: $fontFamilyBold;
                    font-size: 16px;
                    line-height: 21px;
                    color: #4f4f4f;
                    margin-bottom: 5px;
                }
                .goal__text {
                    font-family: $fontFamily;
                    font-size: 16px;
                    line-height: 21px;
                    color: #4f4f4f;
                }
            }
        }
    }
    &__column {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: calc(50% - 15px);
        gap: 30px;
    }
    &__green {
        display: flex;
        width: 100%;
        padding: 40px;
        gap: 30px;
        background: #e7ffea;
        border-radius: 13px;
        font-size: 16px;
        line-height: 21px;
        color: #303030;
        font-family: $fontFamily;
        b {
            font-family: $fontFamilyBold;
        }
        img {
            width: 40px;
        }
    }
    &__row {
        width: 100%;
        display: flex;
        gap: 20px;
    }
    &__promo {
        width: 100%;
        padding: 40px;
        gap: 30px;
        background: url('#{$assetsPath}/img/bonus.svg') #f7f8fa no-repeat right center;
        background-size: contain;
        border-radius: 13px;
        padding-right: 50%;
        h1, h2 {
            margin: 0 0 30px;
        }
        p {
            font-size: 16px;
            line-height: 21px;
            margin-bottom: 30px;
            b {
                font-family: $fontFamilySemibold;
            }
        }
        @media (max-width: 767px) {
            p {
                font-size: 14px;
                line-height: 21px;
            }

        }
        @media (max-width: 550px) {
            background-position: bottom right;
            background-size: 50%;
            padding-right: 40px;
            padding-bottom: 50%;
            h1, h2 {
                margin-bottom: 25px;
            }
        }

    }

    @media (max-width: 1023px) {
        flex-direction: column;
        &__goals {
            width: 100%
        }
        &__column {
            width: 100%;
        }
    }
    @media (max-width: 767px) {
        &__row {
            flex-direction: column;
        }
    }
    @media (max-width: 370px) {
        &__green {
            flex-direction: column;
        }
        &__goals {
            ol {
                li {
                    grid-template-columns: 1fr;
                    grid-gap: 10px;
                    margin-bottom: 20px;
                }
            }
        }
    }
}

.advantages {
    position: relative;
    .decoration {
        position: absolute;
        left: 0;
        bottom: -200px;
        z-index: 10;
        @media (max-width: 500px) {
            display: none;
        }
    }
    a {
        color: #5199FF;
    }
    .container {
        display: flex;
        flex-direction: column;
    }
    .actions {
        align-self: center;
        display: flex;
        margin-top: 40px;
        gap: 10px;
        @media (max-width: 650px) {
            position: static;
            margin-top: 25px;
            width: fit-content;
        }
        #arrow-left {
            width: 35px;
            height: 35px;
            background: url("#{$assetsPath}/img/arrow-gray.svg") center center no-repeat;
            background-size: 100%;
            &.active {
                cursor: pointer;
                background: url("#{$assetsPath}/img/arrow-green.svg") center center no-repeat;
                background-size: 100%;
                transform: rotate(180deg);
            }
        }
        #arrow-right {
            width: 35px;
            height: 35px;
            background: url("#{$assetsPath}/img/arrow-green.svg") center center no-repeat;
            cursor: pointer;
            background-size: 100%;
            &.inactive {
                background: url("#{$assetsPath}/img/arrow-gray.svg") center center no-repeat;
                background-size: 100%;
                transform: rotate(180deg);
                cursor: auto;
            }
        }
    }
    .advantages-container {
        position: relative;
        width: 100%;
        overflow: hidden;
        height: 122px;
    }
    .advantages-list {
        display: flex;
        gap: 20px;
        position: absolute;
        top: 0;
        left: 0;
        transition: 0.5s left;
        &__item {
            flex-shrink: 0;
            background: #F7F8FA;
            border-radius: 13px;
            padding: 30px;
            width: 359px;
            display: flex;
            align-items: center;
            transition: 0.5s left ease;
            height: 100%;
            min-height: 110px;
            border: 1px solid #FAFAFB;
            &:hover {
                border: 1px solid #35C768;;
            }
            img {
                margin-right: 20px;
            }
            @media (max-width: 650px) {
                width: 100%;
                height: unset;
            }
        }
        @media (max-width: 650px) {
            display: grid;
            grid-template-rows: repeat(1, 120px);
            grid-template-columns: repeat(9, calc(100vw - 32px));
            grid-auto-flow: column;
            grid-gap: 10px;
        }
    }
}

.todo {
    &__container {
        background: #F7F8FA;
        border-radius: 13px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 70px 69px;
        @media (max-width: 1200px) {
            padding: 70px 30px;
        }
        @media (max-width: 767px) {
            padding: 50px 30px;
        }
        @media (max-width: 500px) {
            padding: 30px;
        }
    }
    &__list {
        display: flex;
        margin-bottom: 50px;
        justify-content: space-between;
        @media (max-width: 767px) {
            flex-direction: column;
            justify-content: center;
            margin-bottom: 30px;
        }
    }
    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        &:not(:last-child) {
            margin-right: 15px;
        }
        &--number {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background: rgba(159, 226, 172, 0.5);
            display: flex;
            align-items: center;
            justify-content: center;
            color: #36BD7C;
            font-size: 20px;
            margin-bottom: 30px;
        }

        &--title {
            font-size: 18px;
            line-height: 22px;
            font-family: $fontFamilySemibold;
            margin-bottom: 15px;
        }

        &--text {
            font-size: 16px;
            line-height: 21px;
            max-width: 300px;
        }
        @media (max-width: 767px) {
            &:not(:last-child) {
                margin-bottom: 15px;
                margin-right: 0;
            }
            &--text {
                max-width: unset;
            }
        }

        @media (max-width: 500px) {
            &--title {
                font-size: 16px;
                line-height: 19px;
            }
            &--text {
                font-size: 14px;
                line-height: 19px;
            }
        }
    }
    .promo__button {
        button {
            width: 350px;
            @media (max-width: 550px) {
                width: 100%;
                font-size: 16px;
            }
        }
        @media (max-width: 550px) {
            width: 100%;
        }
    }
}

.reviews {
    .bottom-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    a.more {
        color: #5199ff;
        margin-top: 30px;
    }
    .actions {
        align-self: center;
        display: flex;
        margin-top: 30px;
        gap: 10px;
        @media (max-width: 767px) {
            display: none;
        }
        #arrow-left-reviews {
            width: 35px;
            height: 35px;
            background: url("#{$assetsPath}/img/arrow-gray.svg") center center no-repeat;
            background-size: 100%;
            &.active {
                cursor: pointer;
                background: url("#{$assetsPath}/img/arrow-green.svg") center center no-repeat;
                background-size: 100%;
                transform: rotate(180deg);
            }
        }
        #arrow-right-reviews {
            width: 35px;
            height: 35px;
            background: url("#{$assetsPath}/img/arrow-green.svg") center center no-repeat;
            cursor: pointer;
            background-size: 100%;
            &.inactive {
                background: url("#{$assetsPath}/img/arrow-gray.svg") center center no-repeat;
                background-size: 100%;
                transform: rotate(180deg);
                cursor: auto;
            }
        }
    }
    &-container {
        position: relative;
        width: 100%;
        overflow: hidden;
        height: 600px;
        @media (max-width: 767px) {
            height: unset;
        }
    }
    &-list {
        position: absolute;
        top: 0;
        left: 0;
        transition: 0.5s left;
        width: 100%;
        height: 100%;
        display: flex;
        white-space: nowrap;
        scrollbar-width: none;
        @media (max-width: 767px) {
            position: static;
        }

        @media not all and (min-resolution: 0.001dpcm) {
            @supports (-webkit-appearance: none) {
                scroll-snap-type: unset;
            }
        }

        @media (max-width: 767px) {
            overflow: scroll;
            scroll-snap-type: x mandatory;
            -ms-overflow-style: none;
            -webkit-overflow-scrolling: touch;
        }

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .reviews-block {

        &__item {
            flex-shrink: 0;
            width: calc(33.333% - 20px);
            margin-right: 20px;
            scroll-snap-align: start;

            @media (max-width: 1209px) {
                width: calc(33.333% - 14px);
            }

            @media (max-width: 1023px) {
                width: calc(33.333% - 20px);
            }

            @media (max-width: 767px) {
                width: calc(50% - 10px);
                min-width: 247px;
            }

            &--double {
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .reviews-block__review {
                    height: calc(50% - 5px);
                }
            }

            &:last-child {
                margin-right: 0;
            }
        }

        &__review {
            display: flex;
            flex-direction: column;
            height: 100%;
            padding: 30px;
            white-space: normal;
            background: #f7f8fa;
            border-radius: 13px;

            @media (max-width: 767px) {
                padding: 20px;
            }
        }

        &__company {
            margin-bottom: 30px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &__company-logo {
            width: 96px;
            height: 32px;

            img {
                width: 100%;
                height: auto;
                max-height: 32px;
                object-fit: contain;
            }
        }

        &__company-rating {
            display: flex;

            span {
                display: block;
                padding-right: 2px;
                font-size: 14px;
                line-height: 14px;
                font-family: $fontFamilyBold;
                color: #f2994a;
            }

            svg {
                display: block;
                margin-left: 8px;
                width: 13px;
                height: 12px;

                @media (max-width: 767px) {
                    margin: -1px 0 0 5px;
                    width: 13px;
                    height: 13px;
                }

                path {
                    fill: #e0e0e0;
                    stroke: #e0e0e0;
                }

                &.filled path {
                    fill: #f2994a;
                    stroke: #f2994a;
                }
            }
        }

        &__title-company-rating {
            display: inline;

            span {
                margin-left: 6px;
                padding-right: 2px;
                font-size: 20px;
                line-height: 14px;
                font-family: $fontFamilyBold;
                color: #fabc05;
            }

            svg {
                margin-left: 16px;
                width: 18px;
                height: 16px;

                path {
                    fill: #e0e0e0;
                    stroke: #e0e0e0;
                }

                &.filled path {
                    fill: #f2994a;
                    stroke: #f2994a;
                }
            }
        }

        &__assist,
        &__country {
            margin-bottom: 5px;
            font-size: 13px;
            line-height: 16px;
            font-family: $fontFamily;
            color: #8c8c8c;

            span {
                color: #4b4b4b;
            }
        }

        &__text-title {
            margin-bottom: 20px;
            font-size: 15px;
            line-height: 18px;
            font-family: $fontFamilySemibold;
            color: #303030;
            transition: opacity 0.5s ease;
            margin-top: 30px;

    &:hover,
    &:focus,
    &:active {
        opacity: 0.7;
    }

            &.extended {
                margin-top: 30px;
            }
        }

        &__text-content {
            display: block;
            margin-bottom: 0;
            font-size: 14px;
            line-height: 20px;
            font-family: $fontFamily;
            color: #303030;
            word-break: break-word;

            a {
                color: #5199ff;
                transition: opacity 0.5s ease;

    &:hover,
    &:focus,
    &:active {
        opacity: 0.7;
    }
            }
        }

        &__author {
            margin-top: auto;
            padding-top: 20px;
            display: flex;
            align-items: center;
        }

        &__author-avatar {
            margin-right: 11px;
            min-width: 39px;
            width: 39px;
            height: 39px;
            background: url('#{$assetsPath}/img/ico-avatar.svg') no-repeat center /
      cover;

            @media (max-width: 1209px) {
                background-image: url('#{$assetsPath}/img/ico-avatar-blue.svg');
            }
        }

        &__author-meta {
            p {
                margin-bottom: 0;
                font-size: 14px;
                line-height: 17px;
                color: #4b4b4b;
                font-family: $fontFamilySemibold;
            }

            span {
                margin-top: 6px;
                display: block;
                font-size: 13px;
                line-height: 16px;
                color: #8c8c8c;
                font-family: $fontFamily;
            }
        }

        &__link {
            margin: 40px auto 0;
            display: block;
            font-size: 16px;
            line-height: 20px;
            font-family: $fontFamily;
            color: #5199ff;
            transition: opacity 0.5s ease;

    &:hover,
    &:focus,
    &:active {
        opacity: 0.7;
    }

            @media (max-width: 767px) {
                margin-top: 30px;
            }
        }
    }
}

.questions {
    a {
        color: #5199FF;
    }
    ul {
        list-style: disc;
        padding-left: 20px;
    }
    &-container {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    &__item {
        background: #F7F8FA;
        border-radius: 13px;
        padding: 20px 30px;
        font-size: 16px;
        line-height: 19px;
        cursor: pointer;
        margin-bottom: 15px;
        .question {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .arrow {
                width: 24px;
                height: 24px;
                background: url("#{$assetsPath}/img/arrow.svg") center center no-repeat;
                transition: 0.5s ease;
            }
        }
        .answer {
            margin-top: 0;
            height: 0;
            overflow: hidden;
            color: #F7F8FA;
            transition: margin-top 0.5s, color 0.5s;
        }

        &.active {
            .question {
                color: #5199FF;
                .arrow {
                    transform: rotate(180deg);
                }
            }
            .answer {
                margin-top: 15px;
                color: #303030;
                height: fit-content;
                opacity: 1;
            }
        }
    }
}

.insurances {
    .bottom-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        &__footer {
            margin-top: 15px;
            text-align: center;
        }
    }
    .actions {
        align-self: center;
        display: flex;
        margin-top: 40px;
        gap: 10px;
        @media (max-width: 650px) {
            position: static;
            margin-top: 25px;
            width: fit-content;
        }
        #arrow-left-insurances {
            width: 35px;
            height: 35px;
            background: url("#{$assetsPath}/img/arrow-gray.svg") center center no-repeat;
            background-size: 100%;
            &.active {
                cursor: pointer;
                background: url("#{$assetsPath}/img/arrow-green.svg") center center no-repeat;
                background-size: 100%;
                transform: rotate(180deg);
            }
        }
        #arrow-right-insurances {
            width: 35px;
            height: 35px;
            background: url("#{$assetsPath}/img/arrow-green.svg") center center no-repeat;
            cursor: pointer;
            background-size: 100%;
            &.inactive {
                background: url("#{$assetsPath}/img/arrow-gray.svg") center center no-repeat;
                background-size: 100%;
                transform: rotate(180deg);
                cursor: auto;
            }
        }
    }
    &__container {
        position: relative;
        width: 100%;
        overflow: hidden;
        height: 309px;
        @media (max-width: 650px) {
            height: 434px;
        }
    }
    &__list {
        position: absolute;
        top: 0;
        left: 0;
        transition: 0.5s left;
        width: 100%;
        display: flex;
        white-space: nowrap;
        scrollbar-width: none;

        @media not all and (min-resolution: 0.001dpcm) {
            @supports (-webkit-appearance: none) {
                scroll-snap-type: unset;
            }
        }

        @media (max-width: 650px) {
            display: grid;
            grid-template-rows: repeat(3, 138px);
            grid-template-columns: repeat(6, calc(100vw - 32px));
            grid-auto-flow: column;
            grid-gap: 10px;
        }
    }

    .insurance-block {
        &__card {
            height: 309px;
            position: relative;
            width: 100%;
            padding: 105px 30px 30px;
            border: 1px solid #F2F2F2;
            background-color: #F7F8FA;
            border-radius: 13px;
            transition: opacity 1s ease;
            margin-right: 20px;
            flex-shrink: 0;

            &::before {
                content: '';
                position: absolute;
                top: 30px;
                left: 50%;
                width: 50px;
                height: 50px;
                transform: translateX(-50%);
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
            }

            &--new::after {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                width: 50px;
                height: 50px;
                background: url("#{$publicPath}/images/calculator_icons/ico-new.svg") no-repeat center;
                background-size: cover;
            }

            &--mortgage {
                &::before {
                    background-image: url("#{$publicPath}/images/calculator_icons/offers/mortgage.svg");
                }
            }

            &--viza {
                &::before {
                    background-image: url("#{$publicPath}/images/calculator_icons/offers/viza.svg");
                }
            }

            &--vzr {
                &::before {
                    background-image: url("#{$publicPath}/images/calculator_icons/offers/vzr.svg");
                }
            }

            &--antimite {
                &::before {
                    background-image: url("#{$publicPath}/images/calculator_icons/offers/antimite.svg");
                }
            }

            &--dmc-juridical {
                &::before {
                    background-image: url("#{$publicPath}/images/calculator_icons/offers/family.png");
                }
            }

            &--osago {
                &::before {
                    background-image: url("#{$publicPath}/images/calculator_icons/offers/osago.svg");
                }
            }

            &--eosago {
                &::before {
                    background-image: url("#{$publicPath}/images/calculator_icons/offers/eosago.svg");
                }
            }

            &--cancel {
                &::before {
                    background-image: url("#{$publicPath}/images/calculator_icons/offers/cancel.svg");
                }
            }

            &--green {
                &::before {
                    background-image: url("#{$publicPath}/images/calculator_icons/offers/green.svg");
                }
            }

            &--telemed {
                &::before {
                    background-image: url("#{$publicPath}/images/calculator_icons/offers/telemed.svg");
                }
            }

            &--vzr-russia {
                &::before {
                    background-image: url("#{$publicPath}/images/calculator_icons/offers/vzr-russia.svg");
                }
            }

            &--vzr-crimea {
                &::before {
                    background-image: url("#{$publicPath}/images/calculator_icons/offers/vzr-crimea.svg");
                }
            }

            &--kasko {
                &::before {
                    background-image: url("#{$publicPath}/images/calculator_icons/offers/kasko.svg");
                }
            }

            &--dms {
                &::before {
                    background-image: url("#{$publicPath}/images/calculator_icons/offers/dms.svg");
                }
            }

            &--foreign {
                &::before {
                    background-image: url("#{$publicPath}/images/calculator_icons/offers/foreign.svg");
                }
            }

            &--migrants {
                &::before {
                    background-image: url("#{$publicPath}/images/calculator_icons/offers/migrants.svg");
                }
            }

            &--flat {
                &::before {
                    background-image: url("#{$publicPath}/images/calculator_icons/offers/flat.svg");
                }
            }

            &--sport {
                &::before {
                    background-image: url("#{$publicPath}/images/calculator_icons/offers/sport.svg");
                }
            }

            &--accident {
                &::before {
                    background-image: url("#{$publicPath}/images/calculator_icons/offers/accident.svg");
                }
            }

            &--property {
                &::before {
                    background-image: url("#{$publicPath}/images/calculator_icons/offers/property.svg");
                }
            }

            &--onkologia {
                &::before {
                    background-image: url("#{$publicPath}/images/calculator_icons/offers/onkologia.svg");
                }
            }

            &--vactination {
                &::before {
                    background-image: url("#{$publicPath}/images/calculator_icons/offers/vactination.svg");
                }
            }

            &--coronavirus {
                &::before {
                    background-image: url("#{$publicPath}/images/calculator_icons/offers/coronavirus.svg");
                }
            }
        }

        .fade-leave-active {
            display: none;
        }

        .fade-enter {
            opacity: 0;
        }

        &__card-title {
            display: block;
            height: 40px;
            margin-bottom: 10px;
            font-size: 16px;
            line-height: 20px;
            text-align: center;
            color: $fontColor;
            font-family: $fontFamilyBold;
            white-space: normal;
        }

        &__card-title-mobile {
            display: none;
        }

        &__card-description {
            display: block;
            height: 54px;
            font-size: 14px;
            line-height: 18px;
            color: rgba(48, 48, 48, 0.8);
            text-align: center;
            white-space: normal;

            &--mobile {
                display: none;
            }
            margin-bottom: 0;
        }

        &__card-price {
            margin-top: 25px;
            margin-bottom: 0;
            line-height: 44px;
            display: block;
            font-size: 18px;
            font-family: $fontFamily;
            text-align: center;
            color: $fontColor;
        }

        &__card-btn {
            margin-top: 25px;
            display: none;
            width: 100%;
            font-family: $fontFamilyBold;
            text-align: center;
            padding: 10px 20px;
            &:hover {
                border: 1px solid #FFAD32;
                background-color: #fff;
                color: #FFAD32;
            }
        }
    }
    @media (max-width: 1209px) {
        .insurance-block {
            &__content {
                grid-row-gap: 20px;
            }

            &__card {
                height: 294px;

                &--dev {
                    &::after {
                        bottom: 30px;
                        height: 38px;
                        font-size: 16px;
                        line-height: 20px;
                    }
                }
            }

            &__card-btn {
                margin: 0;
                padding: 0;
                display: block !important;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: transparent;
                border: none;
                font-size: 0;
                outline: none;

                &:hover,
                &:focus,
                &:active {
                    background: transparent;
                    border: none;
                }
            }

            &__card-price {
                display: block !important;
                margin-top: 20px;
                font-size: 16px;
                line-height: 30px;
            }
        }
    }
    @media (max-width: 1023px) {
        .insurance-block {
            &__content {
                align-items: stretch;
                grid-row-gap: 10px;
                margin-top: 35px;
                grid-template-columns: 1fr 1fr 1fr;
                grid-column-gap: 1.3%;
            }
            &__card {
                padding: 100px 14px 30px;
                height: 344px;
            }
        }
    }
    @media (max-width: 650px) {
        .insurance-block {
            padding-bottom: 30px;

            &__content {
                grid-row-gap: unset;
                flex-direction: column;
                margin-top: 10px;
                grid-column-gap: unset;
                grid-template-columns: unset;
                display: flex;
                flex-wrap: nowrap;
                align-items: stretch;
                justify-content: flex-start;
                overflow-x: auto;
            }

            &__card {
                height: 138px;
                width: 100%;
                margin-right: 0;
                margin-bottom: 10px;
                padding: 20px 16px 20px 73px;
                flex-shrink: 0;

                &::before {
                    width: 36px;
                    height: 36px;
                    top: 20px;
                    left: 17px;
                    transform: translateX(0);
                }

                &--dev {
                    &::after {
                        content: '';
                        bottom: unset;
                        left: unset;
                        top: 0;
                        right: 0;
                        width: 30px;
                        height: 30px;
                        background: transparent url("#{$publicPath}/images/calculator_icons/ico-soon.png") no-repeat center;
                        background-size: cover;
                    }
                }

                &--new::after {
                    width: 30px;
                    height: 30px;
                }
            }

            &__card-description {
                height: unset;
                display: none;
            }

            &__card-title {
                text-align: left;
            }

            &__card-price {
                display: block;
                font-size: 14px;
                line-height: 18px;
                text-align: left;
            }
        }
    }
}

.partners {
    &__swiper {
        padding-bottom: 65px;

        .swiper-wrapper {
            display: flex;
            align-items: center;
        }

        & > .partners__swiper-pagination {
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translateX(-50%);
            display: flex;

            .swiper-pagination-bullet {
                margin: 0 7px;
                width: 15px;
                height: 15px;
                background: #E0E0E0;
                opacity: 1;
                border-radius: 50%;
            }

            .swiper-pagination-bullet-active {
                background: $green;
            }
        }
    }

    &__img {
        display: flex;
        justify-content: center;
    }
}
.calculate {
    iframe {
        border-radius: 13px;
    }
}
.about {
    background: url('#{$assetsPath}/img/plane.svg') no-repeat left bottom;

    &__item {
        background: #F7F8FA;
        border-radius: 13px;
        padding: 30px;
        align-items: flex-start;
        &-title {
            font-family: $fontFamilyBold;
            font-size: 18px;
            line-height: 21px;
            margin-bottom: 15px;
            color: #303030;
        }
        &-text {
            font-size: 16px;
            line-height: 21px;
            color: #4F4F4F;
        }
    }
}

.reviews {
    .swiper-wrapper {
        box-sizing: border-box;

        .swiper-slide {
            height: auto;
        }
    }

    &__item {
        align-items: flex-start;
        padding: 30px;
        text-align: left;
        background: #F2F8FD;
    }

    &__item-name {
        display: block;
        margin-bottom: 15px;
        font-size: 18px;
        font-family: $fontFamilyBold;
    }

    &__item-meta {
        display: block;
        margin-bottom: 20px;
        font-size: 14px;
        color: rgba(48, 48, 48, 0.8);
        font-family: $fontFamilyBold;
    }
}

.insurance {
    &__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    &__content {
        width: calc(100% - 640px);
    }

    &__title {
        margin-bottom: 40px;
        font-size: 27px;
        line-height: 30px;
        font-family: $fontFamilySemibold;
    }

    &__item {
        position: relative;
        min-height: 35px;
        padding-top: 6px;
        padding-left: 55px;
        margin-bottom: 25px;
        line-height: 22px;

        &:last-child {
            margin-bottom: 0;
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 35px;
            height: 35px;
            background: no-repeat center;
            background-size: cover;
        }

        &--medal::before {
            background-image: url('#{$assetsPath}/img/medal.svg');
        }

        &--earth::before {
            background-image: url('#{$assetsPath}/img/earth.svg');
        }

        &--cards::before {
            background-image: url('#{$assetsPath}/img/credit-cards.svg');
        }
    }
}

@media (max-width: 1209px) {
    .main-content {
        padding: 40px 0;

        &__polis-link {
            margin-bottom: 58px;
        }

        &__form {
            padding-left: calc(100% - 580px);
            background-size: calc(100% - 590px) auto;
        }
    }

    .promo {
        &__message {
            font-size: 18px;
            line-height: 22px;
            margin-bottom: 20px;
        }

        &__item {
            margin-bottom: 20px;
        }
    }

    .steps {
        &__item {
            width: calc(33.333% - 14px);
            padding: 260px 20px 30px;
        }


    }

    .advantages {
        &__item {
            width: calc(33.333% - 14px);
            padding: 210px 5px 60px;
        }
    }

    .reviews {
        &__item {
            width: calc(33.333% - 14px);
            padding: 30px 20px;
        }
    }

    .insurance {
        &__content {
            width: calc(100% - 600px);
        }

        &__title {
            margin-bottom: 30px;
            font-size: 24px;
            line-height: 29px;
        }
    }
}

@media (max-width: 1023px) {
    .main-content {
        &__title {
            font-size: 24px;
            line-height: 29px;
        }

        &__text {
            font-size: 18px;
            line-height: 20px;
        }

        &__form {
            width: 600px;
            margin: 0 auto;
            padding: 0;
            background: transparent;
        }
    }

    .promo {
        &__content-img {
            width: 49.2%;
        }

        &__message {
            font-size: 16px;
            line-height: 20px;
        }

        &__item {
            margin-bottom: 15px;
            padding-top: 5px;

            &::before {
                width: 30px;
                height: 30px;
            }
        }

        &__item-title {
            margin-bottom: 10px;
            font-size: 16px;
            line-height: 20px;
        }

        &__item-text {
            font-size: 14px;
            line-height: 17px;
        }
    }

    .steps {
        &__item {
            min-height: 330px;
            width: calc(33.333% - 7px);
            padding: 240px 5px 30px;
            background-size: 190px 190px;
        }
    }

    .advantages {
        &__item {
            min-height: 359px;
            width: calc(33.333% - 7px);
            padding: 180px 6px 42px;
            background-size: 90px 90px;
        }

        &__item-text {
            font-size: 15px;
            line-height: 18px;
        }
    }

    .partners {
        &__swiper {
            padding-bottom: 55px;
        }
    }

    .reviews {
        &__item {
            width: calc(33.333% - 7px);
            padding: 30px 10px;
        }
    }

    .insurance {
        &__wrapper {
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
        }

        &__content {
            width: 100%;
        }

        &__title {
            margin-bottom: 40px;
            text-align: center;
        }

        &__list {
            display: none;
        }
    }
}

@media (max-width: 767px) {
    .main-content {
        padding: 30px 0;

        &__logo {
            width: 120px;
            margin-bottom: 10px;
        }

        &__polis-link {
            margin-bottom: 30px;
            font-size: 12px;
            line-height: 15px;
        }

        &__title {
            width: 90%;
            margin: 0 auto 30px;
            font-size: 19px;
            line-height: 23px;
        }

        &__text {
            display: none;
        }

        &__form {
            width: 100%;
            min-height: auto;
        }
    }

    .promo {
        &__content {
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
        }

        &__content-title {
            font-size: 40px;
            line-height: 48px;
            text-align: center;
            margin-bottom: 30px;
        }

        &__button {
           align-self: center;
            margin-top: 30px;
        }

        &__content-text {
            width: 100%;
            display: flex;
            flex-direction: column;
        }

        &__content-img {
            width: 100%;
            margin: 0 auto 30px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &__message:first-of-type {
            margin-bottom: 30px;
        }

        &__item {
            margin-bottom: 15px;
            padding-top: 8px;

            &::before {
                width: 35px;
                height: 35px;
            }
        }

        &__item-title {
            margin-bottom: 12px;
            font-size: 18px;
        }

        &__item-text {
            font-size: 16px;
            line-height: 20px;
        }
    }

    .about {
        &__list {
            flex-direction: column;
        }
        &__item {
            width: 100%;
            margin-bottom: 21px;
        }
    }

    .steps {
        &__list {
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
        }

        &__item {
            min-height: 350px;
            width: 100%;
            padding: 270px 16px 30px;
            margin-bottom: 20px;
            font-size: 14px;
            line-height: 17px;
            background-size: 220px 220px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    .advantages {
        &__list {
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
        }

        &__item {
            width: 100%;
            min-height: 266px;
            padding: 150px 17px 30px;
            margin-bottom: 20px;
            background-position: center 30px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    .reviews,
    .partners {
        &__swiper {
            padding-bottom: 38px;
            & > &-pagination {
                position: absolute;
                left: 50%;
                bottom: 0;
                transform: translateX(-50%);
                display: flex;

                .swiper-pagination-bullet {
                    margin: 0 3px;
                    width: 8px;
                    height: 8px;
                    background: #E0E0E0;
                    opacity: 1;
                    border-radius: 50%;
                }

                .swiper-pagination-bullet-active {
                    background: $green;
                }
            }
        }
    }

    .reviews {
        &__item {
            padding: 30px 16px;
        }
    }

    .insurance {
        &__title {
            margin-bottom: 30px;
            font-size: 18px;
            line-height: 20px;
        }
    }
}
@media (max-width: 550px) {
    .promo {
        &__content-title {
            font-size: 24px;
            line-height: 29px;
            margin-bottom: 25px;
        }

        &__button {
            margin-top: 25px;
            width: 100%;
            button {
                width: 100%;
            }
        }

        &__item-text {
            font-size: 14px;
            line-height: 19px;
        }
    }

    .about {
        &__item {
            &-title {
                font-size: 16px;
                line-height: 21px;
                margin-bottom: 10px;
            }
            &-text {
                font-size: 14px;
                line-height: 19px;

            }
        }
    }
}
