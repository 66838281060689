@font-face {
    font-family: "LatoWeb";
    src: url("#{$assetsPath}/fonts/Lato-Regular.eot"); /* IE9 Compat Modes */
    src: url("#{$assetsPath}/fonts/Lato-Regular.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("#{$assetsPath}/fonts/Lato-Regular.woff2") format("woff2"), /* Modern Browsers */
    url("#{$assetsPath}/fonts/Lato-Regular.woff") format("woff"), /* Modern Browsers */
    url("#{$assetsPath}/fonts/Lato-Regular.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
    font-display: swap;
}

/* Webfont: Lato-Medium */@font-face {
    font-family: "LatoWebMedium";
    src: url("#{$assetsPath}/fonts/Lato-Medium.eot"); /* IE9 Compat Modes */
    src: url("#{$assetsPath}/fonts/Lato-Medium.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("#{$assetsPath}/fonts/Lato-Medium.woff2") format("woff2"), /* Modern Browsers */
    url("#{$assetsPath}/fonts/Lato-Medium.woff") format("woff"), /* Modern Browsers */
    url("#{$assetsPath}/fonts/Lato-Medium.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
    font-display: swap;
}

/* Webfont: Lato-Medium */@font-face {
    font-family: "LatoWebMediumItalic";
    src: url("#{$assetsPath}/fonts/Lato-MediumItalic.eot"); /* IE9 Compat Modes */
    src: url("#{$assetsPath}/fonts/Lato-MediumItalic.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("#{$assetsPath}/fonts/Lato-MediumItalic.woff2") format("woff2"), /* Modern Browsers */
    url("#{$assetsPath}/fonts/Lato-MediumItalic.woff") format("woff"), /* Modern Browsers */
    url("#{$assetsPath}/fonts/Lato-MediumItalic.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
    font-display: swap;
}

@font-face {
    font-family: "LatoWebSemibold";
    src: url("#{$assetsPath}/fonts/Lato-Semibold.eot"); /* IE9 Compat Modes */
    src: url("#{$assetsPath}/fonts/Lato-Semibold.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("#{$assetsPath}/fonts/Lato-Semibold.woff2") format("woff2"), /* Modern Browsers */
    url("#{$assetsPath}/fonts/Lato-Semibold.woff") format("woff"), /* Modern Browsers */
    url("#{$assetsPath}/fonts/Lato-Semibold.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
    font-display: swap;
}

@font-face {
    font-family: "LatoWebBold";
    src: url("#{$assetsPath}/fonts/Lato-Bold.eot"); /* IE9 Compat Modes */
    src: url("#{$assetsPath}/fonts/Lato-Bold.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("#{$assetsPath}/fonts/Lato-Bold.woff2") format("woff2"), /* Modern Browsers */
    url("#{$assetsPath}/fonts/Lato-Bold.woff") format("woff"), /* Modern Browsers */
    url("#{$assetsPath}/fonts/Lato-Bold.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
    font-display: swap;
}
